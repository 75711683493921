(function($) {
  
  var articleShowMoreQuintity = 8;


  function setSquareHeight (){ // if block have class 'square' this function make height == width;
    if($('.square').length){
      $('.square').each(function(){
        var squareWidth = $(this).width();
        if(squareWidth < $(this).width()){
          squareWidth = $(this).width();          
        }
        $(this).css('height', ''+ squareWidth +'px')
      })
    }
  }
  function fourSquareDotsPosition () {
    if($(window).width() <= 991 && $('.four-square-wrapper').length){
      var imgHeight = $('.four-square-wrapper .img-wrapper').height();
      var dotsHeight = $('.four-square-wrapper .slick-dots').height();

      $('.four-square-wrapper .slick-dots').css('top', (imgHeight + 12));
      $('.four-square-wrapper .img-wrapper').css('margin-bottom', (dotsHeight + 20));
    }
  }
  function threeHugeSquareDotsPosition() {
    if($(window).width() <= 991 && $('.three-huge-square-btns').length){
      var imgHeight = $('.three-huge-square-btns__item').height();
      var dotsHeight = $('.four-square-wrapper .slick-dots').height();
      $('.three-huge-square-btns .three-huge-square-btns__slider .slick-dots').css('top', (imgHeight + 12));
      $('.three-huge-square-btns__wrapper .btn-wrapper').css('padding-top', (dotsHeight + 20));
    }
  }
  function indentationFromHeader(){
    var headerHeight = $('header').outerHeight();
    if($('.indentation-from-header').length){
      $('.indentation-from-header').css('padding-top', headerHeight)
    }
  }
  function tabsThreeHugeSquareMobileSlider (setCurrentTab) {
    new Promise (function(resolve, reject){  // first remove all elemnts and add what we need 
      if($(window).width() <= 991){
        var currentTab = setCurrentTab;
  
        $('.tabs-three-huge-square .mobile-slider').remove();
        $('.tabs-three-huge-square').append(
          '<div class="mobile-slider main-paddings tabs-three-huge-square__styling"></div>'
          )
        $('.tabs-three-huge-square__item-wrapper').each(function(){
          if(currentTab == 'all'){
    
            var data =  $(this).attr('data-which');
            var markup = $(this).html();
            var wrapperMarkUp = '<div class="tabs-three-huge-square__item-wrapper" data-which="'+ data +'">' + markup +'</div>';
            $('.tabs-three-huge-square .mobile-slider').prepend(wrapperMarkUp);
    
          }
          else{
            
            if($(this).attr('data-which') == currentTab){
    
              var data =  $(this).attr('data-which');
              var markup = $(this).html();
              var wrapperMarkUp = '<div class="tabs-three-huge-square__item-wrapper" data-which="'+ data +'">' + markup +'</div>';
              $('.tabs-three-huge-square .mobile-slider').prepend(wrapperMarkUp);
    
            }
          }
        })
        setSquareHeight ();
        resolve()
      }
    }).then(function(){   // reinit slider
      if( $('.tabs-three-huge-square .mobile-slider').hasClass('slick-initialized')){

        $('.tabs-three-huge-square .mobile-slider').slick('reinit');

      }
      else{
        $('.tabs-three-huge-square .mobile-slider').slick({ // Carusel 
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false
        });
      }
      var dotsLength = $('.tabs-three-huge-square .mobile-slider .slick-dots li').length

      $('.tabs-three-huge-square .mobile-slider').append('<div class="counter"> <p class="quintity">1</p> / <p class="in-which">'+ dotsLength +'</p> </div>')
      var imgHeight = $('.tabs-three-huge-square .mobile-slider .tabs-three-huge-square__item-wrapper .btn-wrapper').outerHeight()
      $('.tabs-three-huge-square .mobile-slider .slick-dots').css('bottom', imgHeight)
      $('.tabs-three-huge-square .mobile-slider .counter').css('bottom', imgHeight)
      $('.tabs-three-huge-square .mobile-slider').on('swipe', function(event, slick, direction){
        if(direction == 'left'){
          if(+$('.tabs-three-huge-square .mobile-slider .counter .quintity').text() < +$('.tabs-three-huge-square .mobile-slider .counter .in-which').text()){
            $('.tabs-three-huge-square .mobile-slider .counter .quintity').text(+$('.tabs-three-huge-square .mobile-slider .counter .quintity').text() + 1)
          }else{
            $('.tabs-three-huge-square .mobile-slider .counter .quintity').text(1)
          }
        }else{
          if(+$('.tabs-three-huge-square .mobile-slider .counter .quintity').text() > 1){
            $('.tabs-three-huge-square .mobile-slider .counter .quintity').text(+$('.tabs-three-huge-square .mobile-slider .counter .quintity').text() - 1)
          }else{
            $('.tabs-three-huge-square .mobile-slider .counter .quintity').text(dotsLength)
          }
        }
      });
    })
  }
  function articleShowMore () {
    var quintity = 4;
    if($('.articles__main-field').length){
      
      $('.article-item').each(function(id){
        $(this).addClass('hide')
        if($(this).hasClass('active') && id < quintity){
          $(this).removeClass('hide')
        }
      })

      if($('.articles__main-field .article-item.active').length < quintity){ // if quintity of items less than 4 then hide show more btn
        $('.articles__main-field .load-more').addClass('hide')
      }
    }
  }
  function sliderWithCircleDotsPosition () {
    if($('.slider-with-circle').length && $(window).width() <= 991){
      var imgHeight = $('.slider-with-circle__item .avatar').height();
      var dotsHeight = $('.slider-with-circle .slick-dots').height();

      $('.slider-with-circle .slick-dots').css('top', (imgHeight + 10))
      $('.slider-with-circle__item .text').css('margin-top', dotsHeight)
    }
  }


  $('.with-submenu a').on('click', function(e){  // open header submenu event;
    e.preventDefault();
    $(this).parent().toggleClass('active');
    $('.telephones').removeClass('active');
    if($('header').hasClass('transparent') && !$('header').hasClass('scrolled') && $(window).width() > 991){
      $('header').toggleClass('header--black')
    }
  })

  $('.sub-sub__menu ul li a').unbind( "click" );

  $('.sub-sub__menu .submenu-title a').on('click', function(e){
    if($(window).width() <= 991){
      $(this).parents('.sub-sub__menu').toggleClass('active');
    }
  })

  $('.telephones a').on('click', function(e){  // open header telephones submenu event;
    e.preventDefault();
    $(this).parent().toggleClass('active');
    $('.with-submenu').removeClass('active')
  })

  $('.intro .scroll-icon a').on('click', function(){  // scroll to next block
    
    $('html, body').animate({
      scrollTop: $(window).height()
    }, 700)
  })

  $('.scroll-to').on('click', function(e){  // scroll to some block when you clicked on link on content page
    e.preventDefault();
    var toWhichBlockToScroll = $(this).attr('href').replace('#', '');
    var scrollTo = $('#'+ toWhichBlockToScroll +'').offset().top;
    var headerHeight = $('header').outerHeight();

    $('html, body').animate({
      scrollTop: (scrollTo - headerHeight - 20)
    }, 700)
  })

  $('.open-popular-question-btn a').on('click', function(e){
    e.preventDefault();
    $('.content-page__sidebar').toggleClass('active')
    if($('.content-page__sidebar').hasClass('active')){
      $("html, body").animate({ 
        scrollTop: $('.content-page__sidebar').offset().top 
      }, 0);
    }
  })

  $('.filter-wrapper .drop-menu-btn a').on('click', function(e){ // open nav-tabs on profile page
    e.preventDefault();
    $('.filter-wrapper .drop-menu-btn .filter-wrapper__tabs').toggleClass('hide');
  })

  $('.tabs-three-huge-square .filter-wrapper__item a').on('click', function(){
    var itemText = $(this).text();
    var whichToShow = $(this).attr('data-which-show');
    $('.tabs-three-huge-square .filter-wrapper__link').each(function(){
      $(this).removeClass('active');
    })
    $(this).addClass('active');
    $('.tabs-three-huge-square .filter-wrapper__drop-menu .drop-menu-btn p').text(itemText);

    var currentTab = $('.filter-wrapper__link.active').attr('data-which-show')
    
    // change active filter items
    if($(window).width() > 991){
      if(whichToShow == 'all'){ // if you chose show all
        $('.tabs-three-huge-square .tabs-three-huge-square__items .tabs-three-huge-square__item-wrapper').each(function(){
          $(this).removeClass('hide')
        })
      
      }
      else{
        $('.tabs-three-huge-square .tabs-three-huge-square__items .tabs-three-huge-square__item-wrapper').each(function(){
    
          if($(this).attr('data-which') == whichToShow){
            $(this).removeClass('hide');
            
          }
          else{
            $(this).addClass('hide');
          }
        })
      }
    }
    else{
      tabsThreeHugeSquareMobileSlider(currentTab);
    }
  })

  $('.articles__main-field .filter-wrapper__item a').on('click', function(e){
    e.preventDefault();
    var itemText = $(this).text();
    var whichToShow = $(this).attr('data-which-show');
    var quintity = 4;

    $('.articles__main-field .filter-wrapper .drop-menu-btn p').text(itemText);
    
    $('.articles__main-field .filter-wrapper__item a').each(function(){
      $(this).removeClass('active');
      
    })
    $(this).addClass('active');
    if(whichToShow == 'all'){
      $('.articles__main-field .articles-items .article-item').each(function(id){
        $(this).addClass('active');
        $(this).addClass('hide');
        if($(this).hasClass('active') && id < 4){
          $(this).removeClass('hide') 
        }
      })
    }
    else{
      $('.articles__main-field .articles-items .article-item').each(function(id){
        $(this).removeClass('active');  // clearfix all active element
        $(this).addClass('hide');   // hide all elements
        if($(this).attr('data-which') == whichToShow){ // if this element is filtred el
          $(this).addClass('active');  // then make this el active
          $('.articles__main-field .articles-items .article-item.active').each(function(index){
            if(index < quintity){
              $(this).removeClass('hide');
            }
          })
        }
      })
    }
    if($('.articles__main-field .article-item.active').length <= quintity){ // if quintity of items less than 4 then hide show more btn
      $('.articles__main-field .load-more').addClass('hide')
    }else{
      $('.articles__main-field .load-more').removeClass('hide')
    }

    quintity = quintity + 4;

    articleShowMoreQuintity = 8; // if you change filter return articleShowMoreQuintity value back articleShowMoreQuintity 
  })

  $('.articles__main-field .load-more a').on('click', function(e){
    e.preventDefault();
    
    var whichTab = $('.articles__main-field .filter-wrapper .filter-wrapper__tabs .filter-wrapper__item .active').attr('data-which-show');
   
    $('.articles__main-field .articles-items .article-item.active').each(function(id){
      if(id < articleShowMoreQuintity){
        $(this).removeClass('hide')
      }
    })
    
    
    if($('.articles__main-field .article-item.active').length < articleShowMoreQuintity){ // if quintity of items less than 4 then hide show more btn
      $('.articles__main-field .load-more').addClass('hide')
    }
    articleShowMoreQuintity = articleShowMoreQuintity + 4;

    // if(whichTab == 'all'){
    //   $('.articles__main-field .articles-items .article-item').each(function(id){
    //     if(id < articleShowMoreQuintity){
    //       $(this).removeClass('hide')
    //     }
    //   })
      
      
    //   if(articleShowMoreQuintity - 1 >  $('.articles__main-field .articles-items .article-item').length){
    //     $('.articles__main-field .load-more').addClass('hide');
    //   }
    //   articleShowMoreQuintity = articleShowMoreQuintity + 4;
    // }

    // else{
    //   $('.articles__main-field .articles-items .article-item').each(function(id){
    //     if(id < articleShowMoreQuintity){
    //       $(this).removeClass('hide')
    //     }
    //   })
    // }
    
  })

  $('.slider-with-circle__item a').on('click', function(e){
    e.preventDefault();
    $(this).find('.text').toggleClass('active');
  })

  if($('.tabs .drop-down').length && $(window).width() <= 991){
    $('.tabs-wrapper .nav-tabs .nav-item').each(function(){ // hide all nav items
      $(this).addClass('hide')
    })
    $('.tabs .drop-down').on('click', function(){ 
      $('.tabs-wrapper .nav-tabs .nav-item').each(function(){
        $(this).toggleClass('hide')
      })
    })
    $('.tabs-wrapper .nav-link').on('click', function(){
      var linkText = $(this).text()
      $('.tabs-wrapper .nav-tabs .nav-item').each(function(){ 
        $(this).addClass('hide')
      })
      $('.tabs .drop-down p').text(linkText)
    })
  }

  $('.content-page__slider .square-with-title a').on('click', function(e){
    e.preventDefault();
    if($(window).width() > 991){
      $('.slider-popup').removeClass('popup-hide')
      $('body').addClass('unscroll')
    }
  })

  $('.slider-popup .close-btn a').on('click', function(e){
    e.preventDefault();
    $('.slider-popup').addClass('popup-hide');
    $('body').removeClass('unscroll')
  })

  //---> All caruseles
  $('.carusel-wrapper').slick({ // Carusel 
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  });

  if($('.four-square-wrapper').length && $(window).width() <= 991){
    $('.four-square-wrapper .slider-wrapper').slick({ // Carusel 
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      adaptiveHeight: true
    });
  }

  if($('.three-huge-square-btns').length && $(window).width() <= 991){
    $('.three-huge-square-btns__slider').slick({ // Carusel 
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      adaptiveHeight: true
    });
  }

  if($('.our-works').length && $(window).width() <= 991){
    $('.our-works .our-works__wrapper').slick({ // Carusel 
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    });
  }

  if($('.content-page__slider').length){
    $('.content-page__slider .slider-wrapper').slick({ // Carusel 
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      adaptiveHeight: true,
      prevArrow: $('.content-page__slider .slider-and-arrow .arrows .prev a'),
      nextArrow: $('.content-page__slider .slider-and-arrow .arrows .next a'),
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            dots: true
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            dots: true
          }
        }
      ]
    });
  }

  if($('.slider-with-circle__wrapper').length){
    $('.slider-with-circle__wrapper').slick({ // Carusel 
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      adaptiveHeight: true,
      prevArrow: $('.slider-with-circle__arrows .prev a'),
      nextArrow: $('.slider-with-circle__arrows .next a'),
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            dots: true
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            dots: true
          }
        }
      ]
    });

  }
  
  if($('.numbers').length){
    if($(window).width() < 991){
      $('.numbers .number-item').each(function(){
        $(this).addClass('square')
      })  
    }
    $(window).resize(function(){
      setSquareHeight();
    })
    setSquareHeight()

    $('.numbers .numbers__wrapper').slick({ // Carusel 
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            dots: true
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            dots: true
          }
        }
      ]
    });
  }

  if($('.slider-popup').length){

    function changeQuintity(side){
      // if()
    }

    $('.slider-popup .big-slider').on('init', function(event, slick){
      var quintity = $('.slider-popup .big-slider__wrapper .slick-dots li').length
      $('.slider-popup .big-slider__wrapper .big-slider__counter .quintity').text(quintity)
    });

    $('.slider-popup .big-slider').slick({ // Carusel 
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: $('.slider-popup .big-slider__wrapper .arrows .prev a'),
      nextArrow: $('.slider-popup .big-slider__wrapper .arrows .next a'),
      dots: true,
      asNavFor: '.slider-popup .small-slider'
    });

    $('.slider-popup .small-slider').slick({ // Carusel 
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
      asNavFor: '.slider-popup .big-slider',
      focusOnSelect: true
    });
  

    $('.slider-popup .big-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      $('.slider-popup .big-slider__wrapper .big-slider__counter .in-which').text(nextSlide + 1)
    });
  }
  //<--- All caruseles




  // ---> Init map
  if($(".map").length){
    var mymap = L.map('mymap').setView([50.4325938, 30.3915824,17], 17);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { 
      maxZoom: 19
    }).addTo(mymap);

    mymap.scrollWheelZoom.disable();

    var markerIcon = L.icon({
      iconUrl: $('.map').attr('data-marker'),  

      iconSize:     [38, 95], // size of the icon
    });

    var marker = L.marker([50.4325938, 30.3915824,17], {icon: markerIcon} ).addTo(mymap);

  }
  // <--- Init map

  $(".to-top-btn a").click(function(e) {
    e.preventDefault();
    $('html, body').animate({
      scrollTop: 0
    }, 800)
  });

  $('.burger').on('click', function(){ 
    $(this).toggleClass("active");
    $('header nav').toggleClass('active');
    $('body').toggleClass('unscroll')
    if($('header').hasClass('transparent') && !$('header').hasClass('scrolled')){
      $('header').toggleClass('header--black')
    }
    if($(window).width() <= 991 && !$('header').hasClass('scrolled')){
      $('header nav.active').css('height', $(window).height())
    }else{
      $('header nav.active').css('height', '100vh')
    }
  })




  $(document).ready(function(){
    setSquareHeight();
    fourSquareDotsPosition();
    threeHugeSquareDotsPosition();
    indentationFromHeader();
    tabsThreeHugeSquareMobileSlider($('.filter-wrapper__link.active').attr('data-which-show'));
    articleShowMore();
    sliderWithCircleDotsPosition();
  
  })

  $(window).scroll(function() {
    if(pageYOffset > 10){
      $('header').addClass('scrolled')
      if($('header').hasClass('transparent')){
        $('header').addClass('header--black')
      }
    }else if(!$('header nav li.with-submenu').hasClass('active')){
      $('header').removeClass('header--black scrolled')
    }
  });

  $(window).resize(function(){
    setSquareHeight();
    fourSquareDotsPosition();
    threeHugeSquareDotsPosition();
    indentationFromHeader()

  })

})(jQuery);